<!-- 创建商品 -->
<template>
	<div class="bg-white px-3 goods_create" style="margin: -20px;margin-top: -1rem;margin-bottom: 0!important;">
		<router-link :to="{name:'shop_ms_list'}" style="position: absolute;top: 12px;left: 200px;">
			<el-button size="mini">回到限时活动</el-button>
		</router-link>


		<el-form ref="form" label-width="80px">
			
			<el-form-item label="活动名称" class="pt-2">
				<el-input v-model="form.title" placeholder="请输入活动名称，不能超过60个字符" class="w-50"></el-input>
			</el-form-item>
			
			<el-form-item label="活动介绍">
				<el-input type="textarea" v-model="form.desc" placeholder="选填，本次秒杀活动摘要" class="w-50"></el-input>
			</el-form-item>
			
			<el-form-item label="开始时间">
				<el-date-picker v-model="form.kssj" type="datetime" placeholder="选择日期时间" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
			</el-form-item>
			
			<el-form-item label="结束时间">
				<el-date-picker v-model="form.jssj" type="datetime" placeholder="选择日期时间" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
			</el-form-item>
			
			<el-form-item label="是否上架">
				<el-radio-group v-model="form.status">
					<el-radio :label="0" border>放入仓库</el-radio>
					<el-radio :label="1" border>立即上架</el-radio>
				</el-radio-group>
			</el-form-item>
		</el-form>
		<el-button type="primary" style="position: fixed;bottom: 50px;right: 50px;" @click="submit">提交</el-button>

	</div>
</template>

<script>
	import { mapState } from 'vuex'; // 引入 全局 公共数据
	export default {
		inject: ['app', 'layout'],
		data() {
			return {
				id: 0,
				form: {
					title: "",
					desc: "",
					status: 1,
					kssj:'',
					jssj:''
				}
			}
		},
		computed: {
			// 引入store-modules的user模块里面的user(存储用户相关的数据)
			...mapState({
				user: state => state.user.user,
			}),
		},

		// 生命周期
		created() {
			console.log(this.user)
			// 获取id
			this.id = this.$route.params.id //拿到当前商品id
			if (this.id) {
				this.layout.showLoading()
				this.axios.get('/admin/ms/read/' + this.id, {
					token: true
				}).then(res => {
					let data = res.data.data
					this.form = data
					this.layout.hideLoading()
				}).catch(err => {
					this.layout.hideLoading()
				})
			}
		},
		methods: {

			//提交
			submit() {

				this.layout.showLoading()
				let url = this.id ? '/admin/ms/' + this.id : '/admin/ms'
				this.form.mamager_id = this.form.mamager_id ? this.form.mamager_id : this.user.id
				this.axios.post(url, this.form, {
					token: true
				}).then(res => {
					console.log(res)
					this.$message({
						type: "success",
						message: this.id ? "修改成功" : "发布成功" //如果this.id存在,就是修改成功,否则就是发布成功
					})
					this.$router.push({
						name: "shop_ms_list"
					})
					this.layout.hideLoading()
				}).catch(err => {
					this.layout.hideLoading()
				})
			}
		},
	}
</script>

<style>
	.goods_create .el-form-item {
		margin-bottom: 15px;
	}
</style>
